import $ from 'jquery';
import 'jquery-ui-sortable-npm';
import 'bootstrap/js/src/modal';

Array.prototype.shuffle = function() {
    for (let i = this.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this[i], this[j]] = [this[j], this[i]];
    }
};

Math.roundAt = function(a, b){
    var factor = Math.pow(10, b);
    return Math.round(a * factor) / factor;
};

Math.randomBetween = function(a, b){
    var min = Math.min(a, b);
    var max = Math.max(a, b);

    var diff = max - min;

    return (Math.random() * diff) + min;
};

$(document).ready(function() {

    $('.sequence-editor').each(function () {
        var $elm = $(this);

        var $textarea = $elm.find('textarea');
        var json = $textarea.val();
        var sequence = null;
        if(json) {
            sequence = JSON.parse(json);
            console.log('loaded sequence', sequence);
        }

        if(sequence == null || sequence === 'null'){
            sequence = [];
        }

        var $list = $elm.find('[data-role=sequence-items]');

        var $addButton = $elm.find('[data-role=add-item]');
        var template = $addButton.closest('.buttongroup').find('script').html();

        $addButton.on('click', function (evt) {
            evt.preventDefault();

            var addedType = $(this).attr('data-type');
            if(addedType=='avatar') {
                var $avatars = $list.find('li');
                if($avatars.length>=5) {
                    $addButton.addClass('button--disabled');
                } else {
                    $addButton.removeClass('button--disabled');
                }
            }

            var $item = $(template);
            $item.find('[name=type]').val(addedType);
            $list.append($item);

            $item.trigger('bless');
            $item.trigger('change');
        });

        $list.on('click', '[data-role=delete]', function (evt) {
            evt.preventDefault();
            var $button = $(this);
            $button.closest('[data-role="sequence-item"]').remove();
            $list.trigger('change');
        });

        $list.on('bless', 'li', function (evt) {
        //     $(evt.target).trigger('change');

            var $elm = $(this);

            var type = $elm.find('[name=type]').val();

            $elm.find('[data-for]').each(function () {
                var isFor = $(this).data('for');
                $(this).toggle(isFor == type);
            });

            $('select[name="target"]').selectize();

            $('select[name="avatar"]').selectize();

			$('select[name="sender"]').selectize();

			function renderEmoji(item, escape){
				if(item.image){
					return `<image src="${escape(item.image)}" alt="${escape(item.name)}"/>`;
				}else{
					return `<div>${escape(item.name)}</div>`;
				}
			}
			$('select[name="emoji"]').selectize({
				render: {
					option: renderEmoji,
					item: renderEmoji
				}
			});

        });

        $list.on('change', 'li', function (evt) {

            $elm.trigger('bless');
        });

        function reloadSequence(newSequence){
            sequence = newSequence;

            $list.html('');

            for (var key in sequence) {
                if (!sequence.hasOwnProperty(key)) {
                    continue;
                }

                var item = sequence[key];

                var data = item.data;

                data.type = item.type;

                var $item = $(template);
                $list.append($item);
                $item.find('[name]').each(function () {
                    var $input = $(this);

                    var name = $input.attr('name');

                    var value = data[name];

                    if ($input.is('select')) {
                        $input.find('option').prop('selected', false).filter(function (index, elm) {
                            return $(elm).val() == value;
                        }).prop('selected', true);
                    } else {
                        $input.val(value);
                    }
                });

                $item.trigger('bless');
            }

            $list.trigger('change');
        }

        reloadSequence(sequence);



        $(this).on('change', function() {

            console.log('change');

            sequence = [];
            $list.find('[data-role=sequence-item]').each(function () {

                var $item = $(this);
                var data = {};

                $item.find('[name]').each(function () {
                    var $input = $(this);
                    var name = $input.attr('name');

                    var value = null;

                    if ($input.is('select')) {
                        value = $input.find('option:selected').val();
                    } else {
                        value = $input.val();
                    }

                    data[name] = value;

                });

                var type = data.type;
                delete data.type;

                sequence.push({ type: type, data: data });

            });

            // console.log('JSON.stringify(sequence)', JSON.stringify(sequence));

            $textarea.val(JSON.stringify(sequence));
        });

        $list.sortable({
            update: function (event, ui) {
                $list.trigger('change');
             }
        });

        $(this).on('click', '[data-role="generate"]', function(evt, ui) {
            evt.preventDefault();
            const players = JSON.parse($('[name=avatars]').val());

            $elm.find('[name=playerCount]')
                .attr('max', players.length)
                .val(players.length);

            $elm.find('[data-role="generator"]').modal('show');

        });

        $(this).on('click', '[data-role="generator"] .generate-sequence', function(evt, ui){
            evt.preventDefault();

            var $modal = $(evt.target).closest('[data-role="generator"]');

            var data = {
                delayMin: 1,
                delayMax: 2.5,
                playerCount: 3,

                playerThrows: 6,
                throwCount: 20
            };

            $modal.find('input, select, textarea').each(function(index, elm){
                let $elm = $(elm);

                data[$elm.attr('name')] = $elm.val();
            });

            console.log('data', data);


            setTimeout(() => {
                $(this).trigger('generate', data);
            }, 0);

            $modal.modal('hide');
        });

        $(this).on('generate', function(evt, data){

            var delayMin = parseInt(data.delayMin);
            var delayMax = parseInt(data.delayMax);
            var playerCount = parseInt(data.playerCount);

            var playerThrows = data.playerThrows;
            var count = data.throwCount;



            var avatars = []; //do not include the player in the avatar pool

            for(var i = 1; i < playerCount; i++){
                avatars.push(i);
            }


            var computerThrows = count - playerThrows;

            var avatarCount = avatars.length;

            var fullRounds = Math.floor(computerThrows / avatarCount);

            var rest = computerThrows % avatarCount;

            var bucket = [];

            for(var i = 0; i < fullRounds; i++){
                bucket.push(...avatars);
            }

            avatars.shuffle();
            for(i = 0; i < rest; i++){
                bucket.push(avatars[i]);
            }

            //exclude one playerthrow to prepend it
            for(i = 0; i < playerThrows - 1; i++){
                bucket.push(0);
            }

            bucket.shuffle();

            //dedouble

            let loopCount = 0;

            let doubles = false;

            do {

                for(i = 1; i < bucket.length; i++){
                    if(bucket[i - 1] === bucket[i]){
                        doubles = true;
                        const nextDifferentIndex = bucket.findIndex(x => x !== bucket[i], i);
                        bucket[i] = bucket[nextDifferentIndex];
                        bucket[nextDifferentIndex] = bucket[i-1];
                    }
                }

            } while(doubles && ++loopCount < 1000);

            //add player to front
            bucket.unshift(0);

            console.log({bucket, loopCount});

            var generatedSequence = []; //sequence starts with player

            for(var key in bucket){
                if(!bucket.hasOwnProperty(key)){
                    continue;
                }

                let avatar = bucket[key];
                generatedSequence.push({ type: 'delay', data: { delay: Math.roundAt(Math.randomBetween(delayMin, delayMax), 2), type: 'delay' } });
                generatedSequence.push({type: 'throw', data: {target: avatar, type: 'throw'}});

            }

            //generatedSequence.pop(); //remove the last delay

            reloadSequence(generatedSequence);
        });


    });

});
